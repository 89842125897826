



















import { Component, Vue } from 'vue-property-decorator';
import {
  altMembers,
  bassMembers,
  sopranoMembers,
  tenorMembers,
} from './membersData';
import { Member, VoiceTableRow } from '@/typings';
import { maxMembersLength } from '@/utils';
import VoiceTable from '@/components/members/VoiceTable.vue';
import MemberMobileTile from '@/components/members/MemberMobileTile.vue';

@Component({ components: { VoiceTable, MemberMobileTile } })
export default class Members extends Vue {
  altMembers: Member[] = altMembers;
  bassMembers: Member[] = bassMembers;
  membersData: VoiceTableRow[] = [];
  sopranoMembers: Member[] = sopranoMembers;
  tenorMembers: Member[] = tenorMembers;

  tabletBreakPoint: MediaQueryList = window.matchMedia('(max-width: 900px)');
  isTablet: boolean = this.tabletBreakPoint.matches;

  mounted(): void {
    const maxLength: number = maxMembersLength(
      this.altMembers,
      this.bassMembers,
      this.sopranoMembers,
      this.tenorMembers
    );

    for (let i = 0; i < maxLength; i++) {
      this.membersData.push({
        sopran: this.sopranoMembers[i] ?? '',
        alt: this.altMembers[i] ?? '',
        tenor: this.tenorMembers[i] ?? '',
        bas: this.bassMembers[i] ?? '',
      });
    }
    this.tabletBreakPoint.onchange = this.mediaQueryHandler;
  }

  mediaQueryHandler(): void {
    this.isTablet = this.tabletBreakPoint.matches;
  }
}
