
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { VoiceTableRow } from '@/typings';

@Component({})
export default class VoiceTable extends Vue {
  @Prop({ type: Array, default: [] }) membersData!: VoiceTableRow[];
}
