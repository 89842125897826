















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Member } from '@/typings';

@Component({})
export default class MemberMobileTile extends Vue {
  @Prop({ type: Array, default: [] }) membersData!: Member[];
}
